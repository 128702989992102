import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Pane } from 'evergreen-ui';
import { Form, Button, Header, Dimmer, Loader } from 'semantic-ui-react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { VALIDATIONS } from '../../../../../constants';

const UpdateCoachForm = ({ loading, onSubmit, coaches, coach, clientUid }) => {
  const {
    register,
    handleSubmit,
    setValue,
    triggerValidation,
    errors,
  } = useForm();

  useEffect(() => {
    register({ name: 'coachUid' }, { required: true });
  }, [register]);

  const onSelectChange = name => async values => {
    if (Array.isArray(values)) {
      setValue(
        name,
        values.map(v => v.value)
      );
    } else {
      setValue(name, values.value);
    }
    await triggerValidation({ name });
  };

  const showErrors = error => {
    if (error) {
      return VALIDATIONS.MESSAGES[error.type];
    }
    return false;
  };

  const onFormSubmit = data => {
    // handle form submit here
    onSubmit({ ...data, clientUid });
  };

  if (!coaches) {
    return (
      <Dimmer active inverted>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Header>
        {coach &&
          coach.profile &&
          coach.profile.email &&
          `Current Coach - ${coach.profile.email}`}
      </Header>
      <Form.Field>
        <label htmlFor="sessionLength">Select New Coach</label>
        <Select
          id="coachUid"
          options={[
            { value: 'remove', label: '<< Remove Coach >>' },
            ...coaches.map(({ auth }) => ({
              value: auth.uid,
              label: auth.email,
            })),
          ]}
          name="coachUid"
          onChange={onSelectChange('coachUid')}
          error={showErrors(errors.coachUid)}
        />
        <span>{showErrors(errors.coachUid)}</span>
      </Form.Field>
      <Pane height={20} />
      <Button primary type="submit" fluid loading={loading} disabled={loading}>
        Save
      </Button>
    </Form>
  );
};

UpdateCoachForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  coaches: PropTypes.array.isRequired,
};

export default UpdateCoachForm;
