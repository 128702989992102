import React from 'react';
import { Pane } from 'evergreen-ui';
import { Menu, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const Layout = ({ email, children, logout, isSuper }) => {
  const history = useHistory();
  return (
    <Pane
      display="flex"
      height="100vh"
      flexDirection="row"
      flexWrap="wrap"
      width="100%">
      <Pane display="flex" flexDirection="column" width="100%">
        <Menu fluid secondary size="large">
          {/* <Menu.Item name="users" onClick={() => history.push('/dashboard')}>
            Dashboard
          </Menu.Item> */}
          <Menu.Item name="users" onClick={() => history.push('/users')}>
            Users
          </Menu.Item>
          <Menu.Item name="users" onClick={() => history.push('/coaches')}>
            Coaches
          </Menu.Item>
          {isSuper && (
            <Menu.Item name="payouts" onClick={() => history.push('/payouts')}>
              Payouts
            </Menu.Item>
          )}
          <Menu.Item name="sessions" onClick={() => history.push('/sessions')}>
            Sessions
          </Menu.Item>
          <Menu.Item name="content" onClick={() => history.push('/content')}>
            Content
          </Menu.Item>
          {/* <Menu.Item name="sessions" onClick={() => history.push('/scripts')}>
            Scripts
          </Menu.Item> */}
          <Menu.Menu position="right">
            <Dropdown item text={email} pointing>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => logout()}>Sign out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
        <Pane padding={25}>{children}</Pane>
      </Pane>
    </Pane>
  );
};

Layout.propTypes = {
  email: PropTypes.string.isRequired,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  logout: PropTypes.func.isRequired,
};

export default Layout;
