import { connect } from 'react-redux';

import Payouts from './Payouts.view';
import { getCoaches } from '../../../redux/coaches/coaches.actions';
import {
  getPayouts,
  approvePayout,
  generateInvoices,
  generatePayouts,
} from '../../../redux/payouts/payouts.actions';

const mapState = ({ coaches, payouts }) => ({ coaches, payouts });

export default connect(mapState, {
  getCoaches,
  getPayouts,
  approvePayout,
  generateInvoices,
  generatePayouts,
})(Payouts);
