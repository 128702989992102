import React, { useCallback, useState } from 'react';
import {
  Button,
  Header,
  Icon,
  Image,
  Modal,
  Dropdown,
} from 'semantic-ui-react';
import moment from 'moment';

import UpdateCoachForm from './UpdateCoachForm';

const UserEditModal = ({
  buttonColor,
  buttonName,
  data,
  actions,
  coaches,
  updateUserCoach,
  updateChat,
  updateVideo,
}) => {
  const {
    coach,
    profile: { email, isProfileComplete, name, uid, photo },
    stripeId,
    chat,
    video,
    credits,
  } = data;
  const { ios } = data;

  // const renderContent = () => (
  //   <Modal.Content>
  //     <Modal.Description>
  //       <Header>User Information</Header>
  //       <p>Stuff that can change</p>
  //       <Image src="https://react.semantic-ui.com/images/wireframe/paragraph.png" />
  //     </Modal.Description>
  //   </Modal.Content>
  // );

  const [chatValue, setChatValue] = useState(chat);
  const [videoValue, setVideoValue] = useState(video);

  const boolOptions = [
    {
      key: 'true',
      text: 'TRUE',
      value: true,
    },
    {
      key: 'false',
      text: 'FALSE',
      value: false,
    },
  ];

  const handleChatChange = useCallback(
    (e, { value }) => {
      setChatValue(value);
      updateChat({ id: uid, subscription: { chat: value } });
    },
    [setChatValue, uid, updateChat]
  );

  const handleVideoChange = useCallback(
    (e, { value }) => {
      setVideoValue(value);
      updateVideo({ id: uid, subscription: { video: value } });
    },
    [setVideoValue, uid, updateVideo]
  );

  const renderImageContent = () => (
    <Modal.Content image>
      <Image
        wrapped
        size="medium"
        src={
          photo || 'https://react.semantic-ui.com/images/wireframe/image.png'
        }
      />
      <Modal.Description>
        <Header>User Information</Header>
        <p>{`UID: ${uid}`}</p>
        <p>{`EMAIL: ${email}`}</p>
        <p>{`IS PROFILE COMPLETE: ${isProfileComplete}`}</p>
        <p>{`STRIPE ID: ${stripeId}`}</p>
        <p>{`SUBSCRIPTION TYPE: ${ios.isSubscribed ? 'Apple' : 'Stripe'}`}</p>
        {ios.isSubscribed && (
          <>
            <Header>Apple In-App Purchase Information</Header>
            <p>{`TRANSACTION ID: ${ios.transactionId}`}</p>
            {ios.originalTransactionDateIOS && (
              <p>{`ORIGINAL PURCHASE DATE: ${moment(
                ios.originalTransactionDateIOS,
                'x'
              ).format('LLLL')}`}</p>
            )}
            {ios.transactionDate && (
              <p>{`LAST RENEWAL DATE: ${moment(ios.transactionDate, 'x').format(
                'LLLL'
              )}`}</p>
            )}
            {ios.expires_at && (
              <p>{`EXPIRES AT: ${moment(ios.expires_at.toMillis()).format(
                'LLLL'
              )}`}</p>
            )}
          </>
        )}
        <Header>Subscription</Header>
        <p>
          <span style={{ marginRight: 16 }}>CHAT:</span>
          <Dropdown
            value={chat || chatValue}
            onChange={handleChatChange}
            placeholder="Select"
            selection
            options={boolOptions}
          />
        </p>
        <p>
          <span style={{ marginRight: 8 }}>VIDEO:</span>
          <Dropdown
            value={video || videoValue}
            onChange={handleVideoChange}
            placeholder="Select"
            selection
            options={boolOptions}
          />
        </p>
        <p>{`CREDITS: ${credits}`}</p>
      </Modal.Description>
    </Modal.Content>
  );

  const renderEditContent = () => (
    <Modal.Content image>
      <Modal.Description>
        <UpdateCoachForm
          coaches={coaches}
          coach={coach}
          onSubmit={updateUserCoach}
          clientUid={uid}
        />
      </Modal.Description>
    </Modal.Content>
  );

  const renderActions = () => (
    <Modal.Actions>
      <Button primary>
        Proceed <Icon name="right chevron" />
      </Button>
    </Modal.Actions>
  );

  return (
    <Modal
      trigger={<Button color={buttonColor}>{buttonName}</Button>}
      centered={false}
      size="large"
      closeIcon>
      <Modal.Header>{name}</Modal.Header>
      {renderImageContent()}
      <Modal.Header />
      {renderEditContent()}
      {/* {photo ? renderImageContent() : renderContent()} */}
      {actions && renderActions()}
    </Modal>
  );
};

UserEditModal.defaultProps = {
  buttonColor: null,
  buttonName: 'View',
  actions: null,
};

export default UserEditModal;

// data to grab
// - user.coach
// - user.coach.uid
// - user.profile
// - email
// - isProfileComplete
// - name
// - uid
// - photo
// - user.stripeId
// - user.subscription
// - chat
// - credits
// - video
// Should also grab plan data here in the future,
// calculation activeSubscription,
// show all subscription history
