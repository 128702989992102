import { connect } from 'react-redux';

import WebAppContent from './WebAppContent.view';
import { getContent, setContent } from '../../../redux/content/content.actions';

const mapState = ({ content }) => ({
  content
});

export default connect(mapState, {
  getContent,
  setContent,
})(WebAppContent);
