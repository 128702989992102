import * as types from './content.types';
import { LOGOUT } from '../auth/auth.types';

const initialState = {
  status: 'pending',
  setContentStatus: 'pending',
  items: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CONTENT.REQUEST:
      return {
        ...state,
        status: 'loading',
      };
    case types.GET_CONTENT.SUCCESS:
      return {
        ...state,
        items: action.payload,
        status: 'success',
      };
    case types.GET_CONTENT.FAILURE:
      return {
        ...state,
        status: 'failed',
      };
    case types.SET_CONTENT.REQUEST:
      return {
        ...state,
        setContentStatus: 'loading',
      };
    case types.SET_CONTENT.SUCCESS:
      return {
        ...state,
        setContentStatus: 'success',
      };
    case types.SET_CONTENT.FAILURE:
      return {
        ...state,
        setContentStatus: 'failed',
      };
    case LOGOUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
