import { connect } from 'react-redux';

import UserEditModal from './UserEditModal';
import { updateUserCoach, updateChat, updateVideo } from '../../../../redux/clients/clients.actions';

export default connect(null, {
  updateUserCoach,
  updateChat,
  updateVideo
})(UserEditModal);
