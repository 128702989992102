import { handleAction, handleActions, combineActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';

import { findAsyncActions } from '../utils';
import * as types from './auth.types';

const initialState = {
  status: 'pending',
  error: null,
  token: null,
  uid: null,
  role: null,
  init: false,
};

const requestReducer = handleAction(
  combineActions(...findAsyncActions(types, 'REQUEST')),
  state => {
    return { ...state, status: 'pending', error: null };
  },
  initialState
);

const errorReducer = handleAction(
  combineActions(...findAsyncActions(types, 'FAILURE')),
  (state, action) => {
    return { ...state, status: 'failed', error: action.payload };
  },
  initialState
);

const successReducer = handleActions(
  {
    [types.VERIFY_ROLE.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      token: action.payload.token,
      uid: action.payload.uid,
      signInProvider: action.payload.signInProvider,
      admin: action.payload.admin,
      super: action.payload.super,
      master: action.payload.master,
      email: action.payload.email,
      role: action.payload.role,
      init: true,
    }),
    [types.LOGIN_WITH_EMAIL.SUCCESS]: (state, action) => ({
      ...state,
      status: 'success',
      token: action.payload.token,
      uid: action.payload.uid,
    }),
    [types.LOGOUT.SUCCESS]: () => ({
      ...initialState,
      status: 'failed',
      init: true,
    }),
  },
  initialState
);

export default reduceReducers(requestReducer, errorReducer, successReducer);
