import { call, put, all, fork, takeLatest } from 'redux-saga/effects';

import loginSaga from './login/login.saga';

import * as types from './auth.types';
import * as Firebase from '../../services/firebase';

function* logout() {
  try {
    yield call(Firebase.logout);
    yield put({ type: types.LOGOUT.SUCCESS });
  } catch (err) {
    yield put({ type: types.LOGOUT.FAILURE, err });
  }
}

function* verifyRole() {
  try {
    const response = yield call(Firebase.verifyRole);
    if (response.admin) {
      yield put({
        type: types.VERIFY_ROLE.SUCCESS,
        payload: response,
      });
    } else {
      yield put({ type: types.LOGOUT.REQUEST });
    }
  } catch (err) {
    yield put({ type: types.VERIFY_ROLE.FAILURE, err });
  }
}

function* setAdminPrivileges(action) {
  try {
    console.log('setAdmin', action.payload);
    const response = yield call(Firebase.setAdminPrivileges, action.payload);
    yield put({
      type: types.SET_ADMIN_PRIVILEGES.SUCCESS,
      payload: response,
    });
    yield console.log('setAdmin response', response);
  } catch (err) {
    yield put({ type: types.SET_ADMIN_PRIVILEGES.FAILURE, err });
  }
}

export default function* authSaga() {
  yield takeLatest(types.SET_ADMIN_PRIVILEGES.REQUEST, setAdminPrivileges);
  yield takeLatest(types.VERIFY_ROLE.REQUEST, verifyRole);
  yield takeLatest(types.LOGOUT.REQUEST, logout);
  yield all([fork(loginSaga)]);
}
