import firebase from 'firebase';

import CollectionManager from './collection';

import 'firebase/functions';

const Invoices = new CollectionManager('invoices');

export const getInvoices = async () => {
  const { currentUser } = firebase.auth();

  if (currentUser) {
    const invoices = await Invoices.getAll();

    if (invoices.empty) {
      return [];
    }

    return invoices.docs.map(doc => doc.data());
  }

  return Promise.reject(new Error('You are not signed in.'));
};

export const deleteInvoice = async () => {};
