import firebase from 'firebase';

import CollectionManager from './collection';

import 'firebase/functions';

const Sessions = new CollectionManager('sessions');

export const sessionsObserver = emitter => {
  const { currentUser } = firebase.auth();

  if (currentUser) {
    return Sessions.collectionRef().onSnapshot(
      snapshot => {
        if (snapshot.emitter) return emitter({});

        return emitter(
          snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        );
      },
      err => {
        console.log(err);
        return emitter(new Error(err));
      }
    );
  }
  return Promise.reject(new Error('You are not signed in.'));
};

export const updateStatus = async ({id, status}) => {
    return Sessions.docRef(id).update({status: status});
}
