import React from 'react';
import { Button, Header, Icon, Image, Modal } from 'semantic-ui-react';

const DynamicModal = ({ buttonColor, buttonName, data, actions }) => {
  const {
    coach,
    profile: { email, isProfileComplete, name, uid, photo },
    stripeId,
    subscription: { chat, credits, video },
  } = data;

  const renderContent = () => (
    <Modal.Content>
      <Modal.Description>
        <Header>User Information</Header>
        <p>Stuff that can change</p>
        <Image src="https://react.semantic-ui.com/images/wireframe/paragraph.png" />
      </Modal.Description>
    </Modal.Content>
  );

  const renderImageContent = () => (
    <Modal.Content image>
      <Image
        wrapped
        size="medium"
        src={
          photo || 'https://react.semantic-ui.com/images/wireframe/image.png'
        }
      />
      <Modal.Description>
        <Header>User Information</Header>
        <p>{`UID: ${uid}`}</p>
        <p>{`EMAIL: ${email}`}</p>
        <p>{`IS PROFILE COMPLETE: ${isProfileComplete}`}</p>
        <p>{`STRIPE ID: ${stripeId}`}</p>
        <Header>Subscription</Header>
        <p>{`CHAT: ${chat}`}</p>
        <p>{`VIDEO: ${video}`}</p>
        <p>{`CREDITS: ${credits}`}</p>
      </Modal.Description>
    </Modal.Content>
  );

  const renderActions = () => (
    <Modal.Actions>
      <Button primary>
        Proceed <Icon name="right chevron" />
      </Button>
    </Modal.Actions>
  );

  return (
    <Modal trigger={<Button color={buttonColor}>{buttonName}</Button>}>
      <Modal.Header>{name}</Modal.Header>
      {renderImageContent()}
      {/* {photo ? renderImageContent() : renderContent()} */}
      {actions && renderActions()}
    </Modal>
  );
};

DynamicModal.defaultProps = {
  buttonColor: null,
  buttonName: 'View',
  actions: null,
};

export default DynamicModal;

// data to grab
// - user.coach
// - user.coach.uid
// - user.profile
// - email
// - isProfileComplete
// - name
// - uid
// - photo
// - user.stripeId
// - user.subscription
// - chat
// - credits
// - video
// Should also grab plan data here in the future,
// calculation activeSubscription,
// show all subscription history
