import { eventChannel } from 'redux-saga';
import {
  takeLatest,
  put,
  call,
  cancel,
  cancelled,
  fork,
  take,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Swal from 'sweetalert2';

import * as Firebase from '../../services/firebase';
import * as types from './invoices.types';

function* getInvoices() {
  try {
    const response = yield call(Firebase.getInvoices);
    console.log('success response', response);
    yield put({ type: types.GET_INVOICES.SUCCESS, payload: response });
  } catch (err) {
    console.log('Get Invoices Error', err);
    yield put({ type: types.GET_INVOICES.FAILURE });
  }
}

export default function* invoicesSaga() {
  yield takeLatest(types.GET_INVOICES.REQUEST, getInvoices);
}
