// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createRootReducer from './rootReducer';
import rootSaga from './rootSaga';

export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;

  const middleware = applyMiddleware(routerMiddleware(history), sagaMiddleware);

  const store = createStore(persistedReducer, composeEnhancers(middleware));
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  return { store, persistor };
}

export default configureStore();
