import React, { useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { VALIDATIONS } from '../../../constants';
import { Input } from '../../ui';
import { Label } from './Login.styled';

const LoginForm = ({ onSubmit }) => {
  const {
    handleSubmit,
    register,
    setValue,
    triggerValidation,
    errors,
  } = useForm();

  useEffect(() => {
    register({ name: 'email' }, { required: true });
    register({ name: 'password' }, { required: true });
  }, [register]);

  const onInputChange = async (name, value) => {
    setValue(name, value);
    await triggerValidation({ name });
  };

  const showErrors = error => {
    if (error) {
      return VALIDATIONS.MESSAGES[error.type];
    }
    return false;
  };

  return (
    <Form size="big" onSubmit={handleSubmit(onSubmit)}>
      <Form.Field error={showErrors(errors.email)}>
        <Label htmlFor="email">Email address</Label>
        <Input
          id="email"
          name="email"
          type="email"
          onChange={e => onInputChange('email', e.target.value)}
        />
      </Form.Field>
      <Form.Field error={showErrors(errors.password)}>
        <Label htmlFor="password">Password</Label>
        <Input
          id="password"
          name="password"
          type="password"
          onChange={e => onInputChange('password', e.target.value)}
        />
      </Form.Field>
      <Button primary type="submit" fluid size="huge">
        Sign in
      </Button>
    </Form>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
