import { eventChannel } from 'redux-saga';
import {
  takeLatest,
  put,
  call,
  cancel,
  cancelled,
  fork,
  take,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Swal from 'sweetalert2';

import * as Firebase from '../../services/firebase';
import * as types from './clients.types';
import { LOGOUT } from '../auth/auth.types';

function* updateUserCredits(action) {
  const showAlertError = () =>
    Swal.fire('Error', 'Unable to update credits. Please try again.', 'error');

  const showAlertSuccess = () =>
    Swal.fire('Success', 'Session credits update successful!', 'success');

  try {
    yield call(Firebase.updateUserCredits, action.payload);
    yield put({
      type: types.UPDATE_USER_CREDITS.SUCCESS,
    });
    yield showAlertSuccess();
  } catch (err) {
    console.log(err);
    yield put({
      type: types.UPDATE_USER_CREDITS.FAILURE,
      payload: err.message,
    });
    showAlertError();
  }
}

function* updateUserCoach(action) {
  const showAlertError = () =>
    Swal.fire('Error', 'Unable to update coach. Please try again.', 'error');

  const showAlertSuccess = () =>
    Swal.fire('Success', 'Coach update successful!', 'success');

  try {
    yield call(Firebase.updateUserCoach, action.payload);
    yield put({ type: types.UPDATE_USER_COACH.SUCCESS });
    yield showAlertSuccess();
  } catch (err) {
    console.log(err);
    yield put({
      type: types.UPDATE_USER_COACH.FAILURE,
      payload: err.message,
    });
    yield showAlertError();
  }
}

function* observeUsers() {
  const channel = eventChannel(emitter => {
    const observer = Firebase.usersObserver(emitter);

    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: types.GET_USERS.SUCCESS,
        payload: response,
      });
    } catch (err) {
      yield put({
        type: types.GET_USERS.FAILURE,
        payload: err,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* getUsers() {
  const observer = yield fork(observeUsers);

  yield take(LOGOUT.REQUEST);
  yield cancel(observer);
}

function* migrateUser({ payload }) {
  try {
    const response = yield call(Firebase.migrateUser, payload);
    yield put({ type: types.MIGRATE_USER.SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.MIGRATE_USER.FAILURE, payload: err.message });
  }
}

function* migrateUserSuccess() {
  yield put(push('/dashboard'));
}

function* updateChat(action) {
  try {
    yield call(Firebase.updateSubscription, action.payload);
    yield put({
      type: types.UPDATE_CHAT.SUCCESS,
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: types.UPDATE_CHAT.FAILURE,
    });
  }
}

function* updateVideo(action) {
  try {
    yield call(Firebase.updateSubscription, action.payload);
    yield put({
      type: types.UPDATE_VIDEO.SUCCESS,
    });
  } catch (e) {
    console.error(e);
    yield put({
      type: types.UPDATE_VIDEO.FAILURE,
    });
  }
}

export default function* clientsSaga() {
  yield takeLatest(types.GET_USERS.REQUEST, getUsers);
  yield takeLatest(types.UPDATE_USER_COACH.REQUEST, updateUserCoach);
  yield takeLatest(types.UPDATE_USER_CREDITS.REQUEST, updateUserCredits);
  yield takeLatest(types.MIGRATE_USER.REQUEST, migrateUser);
  yield takeLatest(types.MIGRATE_USER.SUCCESS, migrateUserSuccess);

  yield takeLatest(types.UPDATE_CHAT.REQUEST, updateChat);
  yield takeLatest(types.UPDATE_VIDEO.REQUEST, updateVideo);
}
