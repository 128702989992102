import { eventChannel } from 'redux-saga';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';
import * as Firebase from '../../services/firebase';
import { LOGOUT } from '../auth/auth.types';
import * as types from './coaches.types';

function* observeCoaches() {
  const channel = eventChannel(emitter => {
    const observer = Firebase.coachesObserver(emitter);

    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: types.GET_COACHES.SUCCESS,
        payload: response,
      });
    } catch (err) {
      yield put({
        type: types.GET_COACHES.FAILURE,
        payload: err,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* getCoaches() {
  const observer = yield fork(observeCoaches);

  yield take(LOGOUT.REQUEST);
  yield cancel(observer);
}

function* createCoach(action) {
  try {
    yield call(Firebase.createCoach, action?.payload?.coach);
    yield action?.payload?.history.goBack();
    yield put({ type: types.CREATE_COACH.SUCCESS });
  } catch (err) {
    console.log('Create Coach Error', err);
    yield put({
      type: types.CREATE_COACH.FAILURE,
      payload: err.message,
    });
  }
}


export default function* migrationSaga() {
  yield takeLatest(types.GET_COACHES.REQUEST, getCoaches);
  yield takeLatest(types.CREATE_COACH.REQUEST, createCoach);
}
