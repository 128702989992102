import firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/auth';

const urlPrefix =
  process.env.NODE_ENV === 'development'
    ? 'us-central1-blush-dev-b8ca2'
    : 'us-central1-blush-production';

const config = Object.freeze({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  cloudFunctions: {
    openTokApi: `https://${urlPrefix}.cloudfunctions.net/openTok`,
  },
});

console.log('*** Firebase Config ***', config);

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export default config;
