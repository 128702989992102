import React, { useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import { onAuthStateChanged } from '../../services/firebase';
import {
  logout,
  verifyRole,
  setAdminPrivileges,
} from '../../redux/auth/auth.actions';

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const { init } = useSelector(state => state.auth);

  // Add admin role here to me
  // MTyeydMLytb9ngt8IIvXbNKaKOE3

  // useEffect(() => {
  //   if (init) {
  //     dispatch(
  //       setAdminPrivileges({
  //         userUid: 'npkKDXsJglbixUsYk92RnlWTKuq1',
  //         adminStatus: true,
  //         superStatus: true,
  //         masterStatus: true,
  //       })
  //     );
  //   }
  // }, [init, dispatch]);

  useEffect(() => {
    onAuthStateChanged(
      () => {
        dispatch(verifyRole());
        // dispatch(
        //   setAdminPrivileges({
        //     userUid: 'npkKDXsJglbixUsYk92RnlWTKuq1',
        //     adminStatus: true,
        //     superStatus: true,
        //     masterStatus: true,
        //   })
        // );
        // dispatch(logout());
      },
      () => {
        dispatch(logout());
      }
    );
  }, [dispatch]);

  if (!init) {
    return (
      <Dimmer active inverted>
        <Loader />
      </Dimmer>
    );
  }

  return children;
};

export default Auth;
