import firebase from 'firebase';

import CollectionManager from './collection';

import 'firebase/functions';

const Content = new CollectionManager('content');

export const fetchContent = async () => {
  let snapshot = await Content.getAll();
  return snapshot?.docs?.map(doc => doc?.data());
};

export const setContent = async (payload) => {
  console.log('payload',payload);
  try{
    if(payload){
      await Content.docRef('dashboard-content').set(payload);
    }
  } catch (e){
    console.error('Error setting quote, tip, and announcements', e.toString());
    throw e;
  }
}
