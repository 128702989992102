function getRandomInt(min, max) {
  const ceilMin = Math.ceil(min);
  const floorMax = Math.floor(max);
  return Math.floor(Math.random() * (floorMax - ceilMin + 1)) + ceilMin;
}

function getTableSortDirection(column) {
  if (column.isSorted) {
    if (column.isSortedDesc) return 'descending';
    return 'ascending';
  }

  return undefined;
}

export { getRandomInt, getTableSortDirection };
