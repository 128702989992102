import React, { useEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import SweetAlert from 'sweetalert2';

import MigrationForm from './Migration.form';
import { Layout } from '../../ui';

const Migration = ({ clients, migrateUser }) => {
  useEffect(() => {
    if (clients.status === 'failed' && clients.error) {
      SweetAlert.fire({ text: clients.error, icon: 'error' });
    }
  }, [clients]);

  return (
    <Layout>
      <Container>
        <Pane width={400} marginLeft="auto" marginRight="auto">
          <MigrationForm
            loading={clients.status === 'loading'}
            onSubmit={migrateUser}
          />
        </Pane>
      </Container>
    </Layout>
  );
};

Migration.propTypes = {
  clients: PropTypes.objectOf(PropTypes.any).isRequired,
  migrateUser: PropTypes.func.isRequired,
};

export default Migration;
