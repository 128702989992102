import firebase from 'firebase';
import { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import CollectionManager from './collection';

const Coaches = new CollectionManager('coaches');

export const coachesObserver = emitter => {
  const { currentUser } = firebase.auth();

  if (currentUser) {
    return Coaches.collectionRef().onSnapshot(
      snapshot => {
        if (snapshot.emitter) return emitter({});

        return emitter(snapshot.docs.map(doc => doc.data()));
      },
      err => {
        console.log(err);
        return emitter(new Error(err));
      }
    );
  }
  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * Get all coaches
 */
export const fetchCoaches = () => {
  const { currentUser } = firebase.auth();
  if (currentUser) {
    return Coaches.getAll().then(snapshot => {
      if (snapshot.empty) {
        return Promise.reject(new Error('You are not signed in.'));
      }
      return snapshot.docs.map(doc => doc.data());
    });
  }
  return Promise.reject(new Error('You are not signed in.'));
};

export const useCoaches = () => {
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    fetchCoaches().then(setCoaches);
  }, []);

  return coaches;
};

export const addRoleToCoach = async () => {
  try {
    await firebase.functions().httpsCallable('addRoleToCoach')();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const migrateMessages = async chatId => {
  try {
    await firebase.functions().httpsCallable('migrateChatMessages')(chatId);
  } catch (err) {
    console.error(err);
    throw err;
  }
};
