import * as types from './clients.types';
import { LOGOUT } from '../auth/auth.types';

const initialState = {
  status: 'pending',
  error: null,
  users: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS.REQUEST:
    case types.MIGRATE_USER.REQUEST:
      return {
        ...state,
        error: null,
        status: 'loading',
      };
    case types.GET_USERS.SUCCESS:
      return {
        ...state,
        users: action.payload,
        status: 'success',
      };
    case types.MIGRATE_USER.SUCCESS:
      return {
        ...state,
        status: 'success',
      };
    case types.MIGRATE_USER.FAILURE:
      return {
        ...state,
        error: action.payload,
        status: 'failed',
      };
    case LOGOUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
