import {
  takeLatest,
  put,
  cancel,
  cancelled,
  fork,
  take, call,
} from 'redux-saga/effects';

import * as Firebase from '../../services/firebase';
import * as types from './content.types';

function* getContent(action) {
  try {
    let response = yield call(Firebase.fetchContent, action?.payload);
    yield put({ type: types.GET_CONTENT.SUCCESS, payload: response});
  } catch (err) {
    console.log('Fetch Content Error', err);
    yield put({
      type: types.GET_CONTENT.FAILURE,
    });
  }
}

function* setContent(action) {
  try {
    yield call(Firebase.setContent, action?.payload);
    yield put({ type: types.SET_CONTENT.SUCCESS});
    yield put({ type: types.GET_CONTENT.REQUEST});
  } catch (err) {
    console.log('Set Content Error', err);
    yield put({
      type: types.SET_CONTENT.FAILURE,
    });
  }
}

export default function* payoutsSaga() {
  yield takeLatest(types.GET_CONTENT.REQUEST, getContent);
  yield takeLatest(types.SET_CONTENT.REQUEST, setContent);
}
