import { createActions } from 'redux-actions';

import * as types from './auth.types';

export const {
  verifyRole,
  loginWithEmail,
  logout,
  setAdminPrivileges,
} = createActions(
  types.VERIFY_ROLE.REQUEST,
  types.LOGIN_WITH_EMAIL.REQUEST,
  types.LOGOUT.REQUEST,
  types.SET_ADMIN_PRIVILEGES.REQUEST
);
