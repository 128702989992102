import * as VALIDATIONS from './validations';

export { VALIDATIONS };

export const INTERESTS = [
  'Breakup/Divorce',
  'Relationships',
  'Friendship',
  'Productivity',
  'Parenting',
  'LGTB-Q',
  'Health/Fitness',
  'Self-Confidence',
  'Finances',
  'Dating',
  'Career',
  'Quarter Life Crisis',
  'Stress Management',
  'Spirituality',
];

export const AVAILABILITY_PREFERENCE = {
  sessionDurations: ['Mornings', 'Afternoons', 'Evenings'],
  daysOfWeek: ['Weekdays', 'Weekend'],
};

export const PASSWORD_WORDS = [
  'sunglasses',
  'alligator',
  'dragon',
  'sunshine',
  'batman',
  'summer',
  'thunder',
  'secret',
  'internet',
  'chicken',
];

export const ADMIN_USERS = [
  'kali@joinblush.com',
  'kelokchan@gmail.com',
  'mattbliffert@icloud.com',
  'gapur.kassym@gmail.com',
  'terencepae@gmail.com',
  'tpae@0ne.io',
  'daniel+client@magnafilis.com',
];

export const PLANS = [
  {
    sku: 'Unlimited Chat(C)',
    uid: 'plan_GJLi1cqpk48gnj',
  },
  {
    sku: 'Video(V_2_30)',
    uid: 'plan_GJLjkMeYkRw2PG',
  },
  {
    sku: 'Video(V_4_30)',
    uid: 'plan_GJLjzOkZBciwdB',
  },
  {
    sku: 'Video(V_4_60)',
    uid: 'plan_GJLoozFqiiF4rk',
  },
  {
    sku: 'Video + Chat(VC_2_30)',
    uid: 'plan_GJLmTP8xg4NRPb',
  },
  {
    sku: 'Video + Chat(VC_4_30)',
    uid: 'plan_GJLmSJbTBMhqxr',
  },
  {
    sku: 'Video + Chat(VC_4_60)',
    uid: 'plan_GJLnLrVBGIBc8v',
  },
];
