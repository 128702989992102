import * as types from './sessions.types';
import { LOGOUT } from '../auth/auth.types';

const initialState = {
  status: 'pending',
  updateStatusStatus: 'pending',
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SESSIONS.REQUEST:
      return {
        ...state,
        status: 'loading',
      };
    case types.GET_SESSIONS.SUCCESS:
      return {
        ...state,
        list: action.payload,
        status: 'success',
      };
    case types.UPDATE_STATUS.REQUEST:
      return {
        ...state,
        updateStatusStatus: 'loading',
      };
    case types.UPDATE_STATUS.SUCCESS:
      return {
        ...state,
        updateStatusStatus: 'success',
      };
    case types.UPDATE_STATUS.FAILURE:
      return {
        ...state,
        updateStatusStatus: 'failed',
      };
    case LOGOUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
