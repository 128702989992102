import { eventChannel } from 'redux-saga';
import {
  // call,
  takeLatest,
  put,
  cancel,
  cancelled,
  fork,
  take,
} from 'redux-saga/effects';
// import { push } from 'connected-react-router';
// import Swal from 'sweetalert2';

import * as Firebase from '../../services/firebase';
import * as types from './subscriptions.types';
import { LOGOUT } from '../auth/auth.types';

function* observeSubscriptions() {
  const channel = eventChannel(emitter => {
    const observer = Firebase.subscriptionsObserver(emitter);
    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: types.GET_SUBSCRIPTIONS.SUCCESS,
        payload: response,
      });
    } catch (err) {
      yield put({
        type: types.GET_SUBSCRIPTIONS.FAILURE,
        payload: err,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* getSubscriptions() {
  const observer = yield fork(observeSubscriptions);

  yield take(LOGOUT.REQUEST);
  yield cancel(observer);
}

export default function* clientsSaga() {
  yield takeLatest(types.GET_SUBSCRIPTIONS.REQUEST, getSubscriptions);
}
