import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { Button, Container, Dimmer, Loader, Table } from 'semantic-ui-react';

import { getTableSortDirection } from '../../../utils';
import { Layout, Modal, Filter } from '../../ui';

const Coaches = ({ coaches, history, getCoaches, disableAccount }) => {
  useEffect(() => {
    if (coaches.status === 'pending') getCoaches();
  }, [coaches.status, getCoaches]);

  const columns = useMemo(
    () => [
      {
        Header: 'Username',
        accessor: 'profile.name',
      },
      {
        Header: 'Email',
        accessor: 'profile.email',
      },
      // {
      //   Header: 'View',
      //   Cell: ({ row }) => {
      //     return (
      //       <Modal
      //         buttonName="Edit Coach"
      //         name={row.original.profile.name}
      //         data={row.original}
      //         // actions={cancelAccount}
      //       />
      //     );
      //   },
      // },
      {
        Header: 'Delete Account',
        Cell: ({ row }) => (
          <Button
            color="red"
            onClick={() => disableAccount({ uid: row.original.profile.uid })}>
            Disable Account
          </Button>
        ),
      },
    ],
    [disableAccount]
  );

  const data = useMemo(() => {
    if (!coaches.list) return [];

    return coaches.list;
  }, [coaches.list]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy: [{ id: 'profile.name' }] },
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Layout>
      <Dimmer.Dimmable as={Container} dimmed={coaches.status === 'loading'}>
        <Dimmer active={coaches.status === 'loading'} inverted>
          <Loader />
        </Dimmer>
        <Pane display="flex" alignItems="center" justifyContent="space-between">
          <Filter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <Button
            primary
            floated="right"
            onClick={() => history.push('/coach-migration')}>
            Create New Coach
          </Button>
        </Pane>
        <Table striped sortable celled {...getTableProps()}>
          <Table.Header>
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell
                    sorted={getTableSortDirection(column)}
                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <Table.Row {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <Table.Cell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Dimmer.Dimmable>
    </Layout>
  );
};

Coaches.propTypes = {
  coaches: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  getCoaches: PropTypes.func.isRequired,
  disableAccount: PropTypes.func.isRequired,
};

export default Coaches;
