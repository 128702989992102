export const MESSAGES = {
  required: 'This is a required field',
  pattern: 'This is not a valid value',
  max: 'Field value must be more',
  min: 'Field value must be less',
};

export const PATTERN_REGEX = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  STRIPE_UID: /^cus_/i,
};
