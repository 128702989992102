import moment from 'moment';

import * as types from './invoices.types';
import { LOGOUT } from '../auth/auth.types';

const initialState = {
  status: 'pending',
  error: null,
  invoices: null,
};

const filterInvoices = invoices => {
  return invoices.filter(invoice => {
    const dateToCheck = moment(invoice.created_at.toDate());
    console.log(moment(invoice.created_at.toDate()).toDate());

    if (moment(dateToCheck).isBefore('2020-04-28')) {
      console.log(moment(invoice.created_at.toDate()).toDate());
    }

    return invoice.status === 'PENDING';
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INVOICES.SUCCESS:
      return {
        ...state,
        invoices: filterInvoices(action.payload),
        status: 'success',
      };
    case LOGOUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
