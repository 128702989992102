import { eventChannel } from 'redux-saga';
import {
  takeLatest,
  put,
  call,
  cancel,
  cancelled,
  fork,
  take,
} from 'redux-saga/effects';

import * as Firebase from '../../services/firebase';
import * as types from './payouts.types';
import { LOGOUT } from '../auth/auth.types';

function* observePayouts() {
  const channel = eventChannel(emitter => {
    const observer = Firebase.payoutsObserver(emitter);

    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: types.GET_PAYOUTS.SUCCESS,
        payload: response,
      });
    } catch (err) {
      yield put({
        type: types.GET_PAYOUTS.FAILURE,
        payload: err,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* getPayouts() {
  const observer = yield fork(observePayouts);

  yield take(LOGOUT.REQUEST);
  yield cancel(observer);
}

function* approvePayout({ payload }) {
  try {
    const response = yield call(Firebase.approvePayout, payload);
    yield put({ type: types.APPROVE_PAYOUT.SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.APPROVE_PAYOUT.FAILURE, payload: err.message });
  }
}

function* generateInvoices({ payload }) {
  try {
    const response = yield call(Firebase.generateInvoices, payload);
    yield put({ type: types.APPROVE_PAYOUT.SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.APPROVE_PAYOUT.FAILURE, payload: err.message });
  }
}

function* generatePayouts({ payload }) {
  try {
    // we manually call payoutsScheduler to generate payouts
    const response = yield call(Firebase.payoutsScheduler, payload);
    yield put({ type: types.APPROVE_PAYOUT.SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: types.APPROVE_PAYOUT.FAILURE, payload: err.message });
  }
}

export default function* payoutsSaga() {
  yield takeLatest(types.GET_PAYOUTS.REQUEST, getPayouts);
  yield takeLatest(types.APPROVE_PAYOUT.REQUEST, approvePayout);
  yield takeLatest(types.GENERATE_INVOICES.REQUEST, generateInvoices);
  yield takeLatest(types.GENERATE_PAYOUTS.REQUEST, generatePayouts);
}
