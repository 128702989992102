import * as types from './subscriptions.types';
import { LOGOUT } from '../auth/auth.types';

const initialState = {
  status: 'pending',
  error: null,
  subscriptions: {},
};

const parseSubs = subs => {
  return subs.reduce((acc, sub) => {
    return {
      ...acc,
      ...(sub.uid && { [sub.uid]: sub }),
    };
  }, {});
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SUBSCRIPTIONS.REQUEST:
      return {
        ...state,
        error: null,
        status: 'loading',
      };
    case types.GET_SUBSCRIPTIONS.SUCCESS:
      return {
        ...state,
        subscriptions: parseSubs(action.payload),
        status: 'success',
      };
    case LOGOUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
