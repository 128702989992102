import PropTypes from 'prop-types';

import React from 'react';
import { Form } from 'semantic-ui-react';
import { useAsyncDebounce } from 'react-table';

const Filter = ({ globalFilter, setGlobalFilter }) => {
  const [filter, setFilter] = React.useState(globalFilter);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value);
  }, 200);

  const handleOnChange = e => {
    setFilter(e.target.value);
    onChange(e.target.value);
  };

  return (
    <Form>
      <Form.Group>
        <Form.Field>
          <Form.Input
            placeholder="Search"
            name="filter"
            value={filter || ''}
            label="Filter"
            onChange={handleOnChange}
            icon="search"
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

Filter.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  globalFilter: '',
};

export default Filter;
