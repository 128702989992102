// React
import React from 'react';

// Redux
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// Routes
import { ConnectedRouter } from 'connected-react-router';
import Routes from './routes';

// Local
import './config/firebase';
import { configureStore, history } from './redux/store';

// CSS / Styling
import GlobalStyle from './theme/globalStyles';
import 'semantic-ui-css/semantic.min.css';
import 'sweetalert2/dist/sweetalert2.css';

import Auth from './components/hoc';

const { store, persistor } = configureStore;

function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <GlobalStyle />
          <Auth>{Routes}</Auth>
        </ConnectedRouter>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
