import React, { useEffect } from 'react';
import { Container, Table, Button, Dimmer, Loader } from 'semantic-ui-react';
import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';

import { Layout } from '../../ui';
import {
  useCoaches,
  migrateUsersToPublic,
  addRoleToCoach,
  generateProfileFields,
} from '../../../services/firebase';

const Dashboard = ({ clients, history, getUsers, getSubscriptions }) => {
  const coaches = useCoaches();

  useEffect(() => {
    getUsers();
    getSubscriptions();
  }, [getUsers, getSubscriptions]);

  if (!clients.users || !coaches) {
    return (
      <Dimmer active inverted>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <Layout>
      <Container>
        <Pane display="flex" justifyContent="flex-end">
          <Button
            primary
            floated="right"
            onClick={() => history.push('/migration')}>
            New User Migration
          </Button>

          <Button
            primary
            floated="right"
            onClick={() => migrateUsersToPublic()}
            disabled={clients.status === 'loading'}>
            Generate Users Public Collection
          </Button>

          <Button
            primary
            floated="right"
            onClick={() => addRoleToCoach()}
            disabled={clients.status === 'loading'}>
            Add Roles to Coaches
          </Button>
          <Button
            primary
            floated="right"
            onClick={() => generateProfileFields()}
            disabled={clients.status === 'loading'}>
            Generate Profile Fields
          </Button>
        </Pane>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Username</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Coach</Table.HeaderCell>
              <Table.HeaderCell>Starting session credits</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {clients.users.map(user => {
              const coach = coaches.find(
                ({ auth }) =>
                  auth.uid === (user.coachUid || (user.coach && user.coach.uid))
              );
              return (
                <Table.Row key={user.auth.uid}>
                  <Table.Cell>{user.profile.name}</Table.Cell>
                  <Table.Cell>{user.profile.email}</Table.Cell>
                  <Table.Cell>{coach ? coach.profile.name : '-'}</Table.Cell>
                  <Table.Cell>{user.subscription.credits}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Container>
    </Layout>
  );
};

Dashboard.propTypes = {
  clients: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  getUsers: PropTypes.func.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
};

export default Dashboard;
