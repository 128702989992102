import React, { useCallback, useEffect } from 'react';
import { Image } from 'semantic-ui-react';
import { Pane } from 'evergreen-ui';
import SweetAlert from 'sweetalert2';
import PropTypes from 'prop-types';

import LoginForm from './Login.form';
import { ADMIN_USERS } from '../../../constants';

const Login = ({ auth, loginWithEmail, history }) => {
  useEffect(() => {
    if (auth.status === 'failed' && auth.error) {
      SweetAlert.fire({ text: auth.error, icon: 'error' });
    }

    if (auth.admin) {
      history.push('/users');
    }
  }, [auth]);

  const onSubmit = useCallback(
    data => {
      loginWithEmail(data);
    },
    [loginWithEmail]
  );

  return (
    <Pane
      display="flex"
      flexDirection="column"
      justifyContent="center"
      width="400px"
      height="100%"
      marginLeft="auto"
      marginRight="auto">
      <Image
        src="/sml-logo.png"
        style={{ width: '128px', objectFit: 'contain' }}
      />
      <LoginForm onSubmit={onSubmit} />
    </Pane>
  );
};

Login.propTypes = {
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  loginWithEmail: PropTypes.func.isRequired,
};

export default Login;
