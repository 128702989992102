import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Pane } from 'evergreen-ui';
import {
  Form,
  Button,
  Header,
  Dimmer,
  Loader,
  Checkbox,
} from 'semantic-ui-react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import {
  VALIDATIONS,
  INTERESTS,
  AVAILABILITY_PREFERENCE,
  PASSWORD_WORDS,
  PLANS,
} from '../../../constants';
import { getRandomInt } from '../../../utils';
import { useCoaches } from '../../../services/firebase';

const MigrationForm = ({ loading, onSubmit }) => {
  const coaches = useCoaches();

  const {
    register,
    handleSubmit,
    setValue,
    triggerValidation,
    errors,
  } = useForm();

  useEffect(() => {
    register({ name: 'name' }, { required: true });
    register({ name: 'email' }, { required: true });
    register({ name: 'coachUid' }, { required: true });
    register(
      { name: 'stripeUid' },
      { required: true, pattern: VALIDATIONS.PATTERN_REGEX.STRIPE_UID }
    );
    register({ name: 'subscriptionSku' }, { required: true });
    register({ name: 'discountAmount' }, { required: true });
    register({ name: 'startingSessionCredits' }, { required: true });
    register({ name: 'video' });
    register({ name: 'chat' });
    register({ name: 'duration' }, { required: true });
  }, [register]);

  const onInputChange = async (e, { name, value }) => {
    setValue(name, value);
    await triggerValidation({ name });
  };

  const onCheckboxChange = async (e, { name, checked }) => {
    setValue(name, Boolean(checked));
    await triggerValidation({ name });
  };

  const onSelectChange = name => async values => {
    if (Array.isArray(values)) {
      setValue(
        name,
        values.map(v => v.value)
      );
    } else {
      setValue(name, values.value);
    }
    await triggerValidation({ name });
  };

  const showErrors = error => {
    if (error) {
      return VALIDATIONS.MESSAGES[error.type];
    }
    return false;
  };

  const onFormSubmit = data => {
    const wordIndex = getRandomInt(0, 9);
    const randomNumber = getRandomInt(11, 99);
    const password = `${PASSWORD_WORDS[wordIndex]}${randomNumber}`;

    onSubmit({
      ...data,
      interests: INTERESTS.slice(0, 3),
      sessionLength: AVAILABILITY_PREFERENCE.sessionDurations,
      days: AVAILABILITY_PREFERENCE.daysOfWeek,
      password,
    });
  };

  if (!coaches) {
    return (
      <Dimmer active inverted>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Header>Current user’s information</Header>
      <Form.Input
        label="Name"
        name="name"
        onChange={onInputChange}
        error={showErrors(errors.name)}
      />
      <Form.Input
        label="Email"
        type="email"
        name="email"
        onChange={onInputChange}
        error={showErrors(errors.email)}
      />
      <Form.Field>
        <label htmlFor="sessionLength">Coach Uid</label>
        <Select
          id="coachUid"
          options={coaches.map(({ auth }) => ({
            value: auth.uid,
            label: auth.email,
          }))}
          name="coachUid"
          onChange={onSelectChange('coachUid')}
          error={showErrors(errors.coachUid)}
        />
        <span>{showErrors(errors.coachUid)}</span>
      </Form.Field>
      <Form.Input
        label="Stripe Uid"
        name="stripeUid"
        onChange={onInputChange}
        error={showErrors(errors.stripeUid)}
      />
      <Form.Field>
        <label htmlFor="sessionLength">Subscription SKU</label>
        <Select
          id="subscriptionSku"
          options={PLANS.map(plan => ({
            value: plan.uid,
            label: plan.sku,
          }))}
          name="subscriptionSku"
          onChange={onSelectChange('subscriptionSku')}
          error={showErrors(errors.subscriptionSku)}
        />
        <span>{showErrors(errors.subscriptionSku)}</span>
      </Form.Field>
      <Form.Input
        label="Discount to apply (in dollars)"
        name="discountAmount"
        onChange={onInputChange}
        error={showErrors(errors.discountAmount)}
      />
      <Form.Input
        label="Starting session credits"
        name="startingSessionCredits"
        type="number"
        onChange={onInputChange}
        error={showErrors(errors.startingSessionCredits)}
      />
      <Form.Field>
        <Checkbox label="Video?" name="video" onChange={onCheckboxChange} />
      </Form.Field>
      <Form.Field>
        <Checkbox label="Chat?" name="chat" onChange={onCheckboxChange} />
      </Form.Field>
      <Form.Field>
        <label htmlFor="duration">Session Duration</label>
        <Select
          id="duration"
          options={[
            { value: '30', label: '30' },
            { value: '60', label: '60' },
          ]}
          name="duration"
          onChange={onSelectChange('duration')}
          error={showErrors(errors.duration)}
        />
        <span>{showErrors(errors.duration)}</span>
      </Form.Field>
      <Pane height={20} />
      <Button primary type="submit" fluid loading={loading} disabled={loading}>
        Save
      </Button>
    </Form>
  );
};

MigrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default MigrationForm;
