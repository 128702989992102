import * as types from './coaches.types';
import { LOGOUT } from '../auth/auth.types';

const initialState = {
  status: 'pending',
  error: null,
  createCoachStatus: 'pending',
  createCoachError: null,
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_COACH.REQUEST:
      return {
        ...state,
        createCoachError: null,
        createCoachStatus: 'loading',
      };
    case types.CREATE_COACH.SUCCESS:
      return {
        ...state,
        createCoachStatus: 'success',
      };
    case types.CREATE_COACH.FAILURE:
      return {
        ...state,
        createCoachError: action.payload,
        createCoachStatus: 'failed',
      };
    case types.GET_COACHES.REQUEST:
      return {
        ...state,
        error: null,
        status: 'loading',
      };
    case types.GET_COACHES.SUCCESS:
      return {
        ...state,
        list: action.payload,
        status: 'success',
      };
    case LOGOUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
