import React, { useEffect, useMemo } from 'react';
import { Container, Table, Button, Dimmer, Loader } from 'semantic-ui-react';
import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';

import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import { Filter, Layout } from '../../ui';
import { getTableSortDirection } from '../../../utils';

const Payouts = ({
  coaches,
  payouts,
  getCoaches,
  getPayouts,
  approvePayout,
  generateInvoices,
  generatePayouts,
}) => {
  useEffect(() => {
    if (coaches.status === 'pending') getCoaches();
  }, [coaches.status, getCoaches]);

  useEffect(() => {
    if (payouts.status === 'pending') getPayouts();
  }, [getPayouts, payouts.status]);

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        id: 'updated_at',
        accessor: row => moment(row.updated_at.toDate()).format('l'),
      },
      {
        Header: 'Coach',
        accessor: 'coachName',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Amount',
        id: 'amount',
        accessor: row => numeral(row.amount / 100).format('$0,0.00'),
      },
      {
        Header: 'Actions',
        id: 'payoutStatus',
        Cell: ({ row }) =>
          (row.original.status === 'PENDING' ||
            row.original.status === 'ERROR') && (
            <Button
              primary
              onClick={() => approvePayout({ payoutId: row.original.id })}
              disabled={payouts.status === 'loading'}>
              Approve
            </Button>
          ),
      },
    ],
    [approvePayout, payouts.status]
  );

  const data = useMemo(() => {
    if (!payouts.list) return [];

    return payouts.list.map(payout => {
      const coach = coaches.list.find(
        ({ auth }) =>
          auth.uid === (payout.coachUid || (payout.coach && payout.coach.uid))
      );

      return {
        ...payout,
        coachName: coach ? coach.profile.name : '-',
      };
    });
  }, [coaches.list, payouts.list]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy: [{ id: 'updated_at', desc: true }] },
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Layout>
      <Dimmer.Dimmable as={Container} dimmed={payouts.status === 'loading'}>
        <Dimmer active={payouts.status === 'loading'} inverted>
          <Loader />
        </Dimmer>
        <Pane display="flex" alignItems="center" justifyContent="space-between">
          <Filter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          {/* <Button
            primary
            floated="right"
            onClick={() => generateInvoices()}
            disabled={payouts.status === 'loading'}>
            Generate Invoices
          </Button> */}
          <Button
            primary
            floated="right"
            onClick={() => generatePayouts()}
            disabled={payouts.status === 'loading'}>
            Generate Payouts
          </Button>
        </Pane>
        <Table striped sortable celled {...getTableProps()}>
          <Table.Header>
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell
                    sorted={getTableSortDirection(column)}
                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <Table.Row {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <Table.Cell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Dimmer.Dimmable>
    </Layout>
  );
};

Payouts.propTypes = {
  coaches: PropTypes.objectOf(PropTypes.any).isRequired,
  payouts: PropTypes.objectOf(PropTypes.any).isRequired,
  getCoaches: PropTypes.func.isRequired,
  getPayouts: PropTypes.func.isRequired,
  approvePayout: PropTypes.func.isRequired,
  generateInvoices: PropTypes.func.isRequired,
  generatePayouts: PropTypes.func.isRequired,
};

export default Payouts;
