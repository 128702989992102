import firebase from 'firebase';

import CollectionManager from './collection';

import 'firebase/functions';

const Subscriptions = new CollectionManager('subscriptions');

/**
 * Get all subscriptions
 */

// eslint-disable-next-line import/prefer-default-export
export const subscriptionsObserver = emitter => {
  const { currentUser } = firebase.auth();

  if (currentUser) {
    return Subscriptions.collectionRef().onSnapshot(
      snapshot => {
        if (snapshot.emitter) return emitter({});

        return emitter(snapshot.docs.map(doc => doc.data()));
      },
      err => {
        console.log(err);
        return emitter(new Error(err));
      }
    );
  }
  return Promise.reject(new Error('You are not signed in.'));
};

export const updateSubscription = async payload => {
  const {
    id,
    subscription: { chat = null, video = null },
  } = payload;
  const { currentUser } = firebase.auth();

  if (currentUser) {
    const user = await Subscriptions.getDoc(id);

    if (user.exists) {
      return Subscriptions.updateDoc(id, {
        ...(chat !== null && { chat }),
        ...(video !== null && { video }),
      });
    }

    return Promise.reject(new Error('User does not exist.'));
  }

  return Promise.reject(new Error('You are not signed in.'));
};
