import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from '../auth/auth.reducer';
import clientsReducer from '../clients/clients.reducer';
import coachesReducer from '../coaches/coaches.reducer';
import payoutsReducer from '../payouts/payouts.reducer';
import invoicesReducer from '../invoices/invoices.reducer';
import sessionsReducer from '../sessions/sessions.reducer';
import contentReducer from '../content/content.reducer';
import subscriptionsReducer from '../subscriptions/subscriptions.reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    coaches: coachesReducer,
    clients: clientsReducer,
    payouts: payoutsReducer,
    invoices: invoicesReducer,
    sessions: sessionsReducer,
    content: contentReducer,
    subscriptions: subscriptionsReducer,
  });
