import { Alert, Pane } from 'evergreen-ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Container,
  Dimmer,
  Loader,
  Table,
  Dropdown,
  Header,
  Form,
  Button,
  Divider,
  Icon,
  Image,
} from 'semantic-ui-react';

import { Layout } from '../../ui';
import { getRandomInt, getTableSortDirection } from '../../../utils';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { PASSWORD_WORDS, VALIDATIONS } from '../../../constants';
import * as firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader';
import { colors } from '../../../theme';
import styled from 'styled-components';

export const MyCoachSticky = styled.div`
  background: ${colors.beautyBush};
  margin-top: 24px;
  .caption {
    display: block;
    text-align: center;
    background: ${colors.geraldine};
    color: ${colors.white};
    font-size: 15px;
    padding: 4px 20px;
    width: max-content;
  }
`;

export const QuoteSticky = styled.div`
  background: ${colors.oldLavender};
  text-align: center;
  color: ${colors.white};
  padding: 36px 44px;
  .icon {
    font-size: 32px;
    margin-top: 12px;
  }
  .header {
    color: ${colors.white};
    margin-top: 16px;
    margin-bottom: 20px;
  }
  .quote-text {
    font-size: 16px;
  }
  .quote-author {
    display: block;
    font-size: 16px;
    margin-top: 20px;
  }
`;

export const TipSticky = styled(MyCoachSticky)`
  background: ${colors.charcoal};
  .tip {
    display: block;
    text-align: center;
    padding: 60px;
    font-size: 20px;
    font-weight: 600;
    color: ${colors.white};
  }
`;

export const List = styled.div`
  && {
    background: ${colors.white};
    margin-top: 24px;
    .header {
      display: flex;
      justify-content: space-between;
      background: ${colors.silver};
      margin: 0;
      padding: 6px 20px;
      font-size: 16px;
      font-weight: 500;
    }
    .header .pagination {
      min-height: 24px;
    }
    .header .pagination .item {
      padding: 4px;
      min-width: 24px;
      justify-content: center;
    }
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.whiteSmoke};
  padding: 12px;
  .image {
    flex: 1 0 16%;
    margin: 0 8px;
  }
  .description {
    margin-left: 12px;
    margin-right: 12px;
    flex: 1 0 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
  .date {
    color: ${colors.oldLavender};
    font-size: 12px;
    flex: 1 0 16%;
  }
`;

const WebAppContent = ({ content, getContent, setContent }) => {
  const {
    register,
    handleSubmit,
    setValue,
    triggerValidation,
    errors,
  } = useForm();

  const [activeUploading, setActiveUploading] = useState(false);

  useEffect(() => {
    register({ name: 'quote.quote' }, { required: true });
    register({ name: 'quote.author' }, { required: true });
    register({ name: 'tip' }, { required: true });
    register({ name: 'announcements[0].image' }, { required: true });
    register({ name: 'announcements[0].description' }, { required: true });
    register({ name: 'announcements[0].date' }, { required: true });
  }, [register]);

  useEffect(() => {
    if (content.status === 'pending') getContent();
  }, [content.status, getContent]);

  const ErrorAlertMessage = useMemo(() => {
    if (content.setContentStatus !== 'failed') return <></>;
    return (
      <Alert
        appearance="card"
        intent="danger"
        title={'Failed to save content. Please try again.'}
      />
    );
  }, [content.setContentStatus]);

  const SuccessAlertMessage = useMemo(() => {
    if (content.setContentStatus !== 'success') return <></>;
    return (
      <Alert appearance="card" intent="success" title={'Successfully saved.'} />
    );
  }, [content.setContentStatus]);

  const quoteDisplay = useMemo(
    () =>
      content.items?.length > 0 && (
        <QuoteSticky>
          <Icon name="quote right" />
          <Header>Quote of the day</Header>
          <span className="quote-text">{content.items[0]?.quote?.quote}</span>
          <span className="quote-author">
            -{content.items[0]?.quote?.author}
          </span>
        </QuoteSticky>
      ),
    [content.items]
  );

  const tipDisplay = useMemo(
    () =>
      content.items?.length > 0 && (
        <TipSticky>
          <span className="caption">Tip of The Week</span>
          <span className="tip">{content.items[0]?.tip}</span>
        </TipSticky>
      ),
    [content.items]
  );

  const announcementDisplay = useMemo(
    () =>
      content.items?.length > 0 && (
        <List style={{ marginBottom: '24px' }}>
          <Header>Company-Wide Announcements</Header>
          {content.items[0]?.announcements.map(item => (
            <ListItem key={item.id}>
              <Image src={item.image} circular size="tiny" />
              <span className="description">{item.description}</span>
              <span className="date">{item.date}</span>
            </ListItem>
          ))}
        </List>
      ),
    [content.items]
  );

  const onInputChange = useCallback(
    async (e, { name, value }) => {
      setValue(name, value);
      await triggerValidation({ name });
    },
    [triggerValidation, setValue]
  );

  const onFormSubmit = useCallback(
    data => {
      setContent(data);
    },
    [setContent]
  );

  const showErrors = error => {
    if (error) {
      return VALIDATIONS.MESSAGES[error.type];
    }
    return false;
  };

  return (
    <Layout>
      <Dimmer.Dimmable as={Container} dimmed={content.status === 'loading'}>
        <Dimmer active={content.status === 'loading'} inverted>
          <Loader />
        </Dimmer>
        <Pane>
          {SuccessAlertMessage}
          {ErrorAlertMessage}
          <Pane height={24} />
          <Form onSubmit={handleSubmit(onFormSubmit)}>
            <Header>Dashboard Content</Header>
            {quoteDisplay}
            <Pane height={12} />
            <Form.TextArea
              label="Quote of the day"
              name="quote.quote"
              onChange={onInputChange}
              error={showErrors(errors.quote)}
            />
            <Form.Input
              label="author"
              name="quote.author"
              onChange={onInputChange}
              error={showErrors(errors.author)}
            />
            <Pane height={20} />
            {tipDisplay}
            <Pane height={12} />
            <Form.TextArea
              label="Tip of the week"
              name="tip"
              onChange={onInputChange}
              error={showErrors(errors.tip)}
            />
            <Pane height={20} />
            {announcementDisplay}
            <Pane height={12} />
            <Form.Field
              error={showErrors(
                errors?.announcements && errors?.announcements[0]?.image
              )}>
              <label>Image</label>
              <FileUploader
                accept="image/*"
                name="image"
                randomizeFilename
                storageRef={firebase.storage().ref('content_images')}
                onUploadStart={() => {
                  setActiveUploading(true);
                }}
                onUploadSuccess={async path => {
                  let url = await firebase
                    .storage()
                    .ref('content_images')
                    .child(path)
                    .getDownloadURL();
                  await onInputChange(null, {
                    name: 'announcements[0].image',
                    value: url,
                  });
                  setActiveUploading(false);
                }}
              />
            </Form.Field>
            <Form.TextArea
              label="Description"
              name="announcements[0].description"
              error={showErrors(
                errors?.announcements && errors?.announcements[0]?.description
              )}
              onChange={onInputChange}
            />
            <Form.Input
              label="Date"
              name="announcements[0].date"
              error={showErrors(
                errors?.announcements && errors?.announcements[0]?.date
              )}
              onChange={onInputChange}
            />
            <Pane height={20} />
            <Button
              primary
              type="submit"
              fluid
              loading={
                content.setContentStatus === 'loading' || activeUploading
              }
              disabled={
                content.setContentStatus === 'loading' || activeUploading
              }>
              Save
            </Button>
          </Form>
        </Pane>
      </Dimmer.Dimmable>
    </Layout>
  );
};

WebAppContent.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  getContent: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
};

export default WebAppContent;
