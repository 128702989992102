import fs from 'fs';
import moment from 'moment';

import React, { useEffect, useMemo, useCallback } from 'react';
import {
  Container,
  Table,
  Button,
  Icon,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';

import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import { Layout, Filter } from '../../ui';
import { getTableSortDirection } from '../../../utils';
import UserEditModal from './UserEditModal';

const Users = ({
  clients,
  coaches,
  subscriptions,
  history,
  getUsers,
  getSubscriptions,
  updateUserCredits,
  disableAccount,
  getCoaches,
  deleteAccount,
}) => {
  useEffect(() => {
    if (clients.status === 'pending') getUsers();
  }, [clients.status, getUsers]);

  useEffect(() => {
    if (coaches.status === 'pending') getCoaches();
  }, [coaches.status, getCoaches]);

  useEffect(() => {
    if (subscriptions.status === 'pending') getSubscriptions();
  }, [subscriptions.status, getSubscriptions]);

  const modifySessionCredit = useCallback(
    (uid, count) => {
      updateUserCredits({ uid, count, action: 'ADMIN ADD OR REMOVE' });
    },
    [updateUserCredits]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Username',
        accessor: 'profile.name',
      },
      {
        Header: 'Email',
        accessor: 'profile.email',
      },
      {
        Header: 'Coach',
        accessor: 'coachName',
      },
      {
        Header: 'Session Credits',
        accessor: 'credits',
      },
      {
        Header: 'Add/Remove Credits',
        Cell: ({ row }) => (
          <Pane display="flex" justifyContent="center">
            <Button icon>
              <Icon
                name="arrow up"
                onClick={() => modifySessionCredit(row.original.auth.uid, 1)}
                color="blue"
              />
            </Button>
            <Button icon>
              <Icon
                name="arrow down"
                onClick={() => modifySessionCredit(row.original.auth.uid, -1)}
                color="red"
              />
            </Button>
          </Pane>
        ),
      },
      {
        Header: 'View',
        Cell: ({ row }) => {
          return (
            <UserEditModal
              buttonName="Edit User"
              name={row.original.profile.name}
              data={row.original}
              coaches={coaches.list}
              // actions={cancelAccount}
            />
          );
        },
      },
      {
        Header: 'Delete Account',
        Cell: ({ row }) => (
          <Button
            color="red"
            onClick={() => disableAccount({ uid: row.original.profile.uid })}>
            Disable Account
          </Button>
        ),
      },
    ],
    [coaches, disableAccount, modifySessionCredit]
  );

  const data = useMemo(() => {
    if (!clients.users) return [];

    return clients.users.map(user => {
      const coach = coaches.list.find(
        ({ auth }) =>
          auth.uid === (user.coachUid || (user.coach && user.coach.uid))
      );

      let credits = 0;
      let chat = false;
      let video = false;
      let ios = {};

      if (user && user.auth && user.auth.uid) {
        if (subscriptions.subscriptions[user.auth.uid]) {
          credits = subscriptions.subscriptions[user.auth.uid].credits;
          chat = subscriptions.subscriptions[user.auth.uid].chat;
          video = subscriptions.subscriptions[user.auth.uid].video;
          ios = subscriptions.subscriptions[user.auth.uid].ios;
        }
      }

      return {
        ...user,
        credits,
        chat,
        video,
        ios,
        coachName: coach ? coach.profile.name : '-',
      };
    });
  }, [clients.users, coaches.list, subscriptions.subscriptions]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy: [{ id: 'profile.name' }] },
    },
    useGlobalFilter,
    useSortBy
  );

  // Add admin role here to me
  // MTyeydMLytb9ngt8IIvXbNKaKOE3

  // const logUsers = () => {
  //   const marketingClients = clients.users
  //     .filter(user => {
  //       return user.profile.isProfileComplete;
  //     })
  //     .filter(user => {
  //       return user.subscription.chat || user.subscription.video;
  //     })
  //     .filter(user => {
  //       return user.created_at;
  //     })
  //     .filter(user => {
  //       return moment(user.created_at.toDate()).isBefore('2020-05-22');
  //     });

  //   const clientsToEmail = marketingClients.map(user => user.auth.email);
  //   console.log(clientsToEmail);
  //   console.log(clients.users.length);
  // };

  // const cancelAccount = uid => {
  //   console.log('Cancelled account', uid);
  // };

  // logUsers();

  return (
    <Layout>
      <Dimmer.Dimmable as={Container} dimmed={clients.status === 'loading'}>
        <Dimmer active={clients.status === 'loading'} inverted>
          <Loader />
        </Dimmer>
        <Pane display="flex" alignItems="center" justifyContent="space-between">
          <Filter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <Button
            primary
            floated="right"
            onClick={() => history.push('/migration')}>
            New User Migration
          </Button>
        </Pane>
        <Table striped sortable celled {...getTableProps()}>
          <Table.Header>
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell
                    sorted={getTableSortDirection(column)}
                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <Table.Row {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <Table.Cell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Dimmer.Dimmable>
    </Layout>
  );
};

Users.propTypes = {
  clients: PropTypes.objectOf(PropTypes.any).isRequired,
  coaches: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  getUsers: PropTypes.func.isRequired,
  getCoaches: PropTypes.func.isRequired,
  updateUserCredits: PropTypes.func.isRequired,
  disableAccount: PropTypes.func.isRequired,
};

export default Users;
