import firebase from 'firebase';
import 'firebase/functions';

export const disableAccount = async data => {
  // data = { uid: ***** }
  try {
    console.log('disable account service called', data);
    return await firebase.functions().httpsCallable('setAccountStatus')({
      ...data,
      accountStatus: true,
    });
  } catch (err) {
    console.error('*** DISABLE ACCOUNT ERROR ***', err);
    throw err;
  }
};

export const deleteAccount = async data => {
  // data = { uid: ***** }
  try {
    return await firebase.functions().httpsCallable('deleteAccount')(data);
  } catch (err) {
    console.error('*** DELETE ACCOUNT ERROR ***', err);
    throw err;
  }
};

export const createCoach = async data => {
  const { currentUser } = firebase.auth();

  if (currentUser) {
    try {
      console.log('Create Coach Service', data);
      const response = await firebase
        .functions()
        .httpsCallable('createNewCoachAdmin')(data);

      if (response.data.errorInfo) {
        return Promise.reject(response.data.errorInfo);
      }

      return response;
    } catch (err) {
      console.error('*** CREATE COACH ERROR ***', err);
      throw err;
    }
  }

  return Promise.reject(new Error('You are not signed in.'));
};

// /**
//  * Verify Role
//  */
// export const verifyRole = async () => {
//   const auth = await firebase
//     .auth()
//     .currentUser.getIdTokenResult(true)
//     .then(idTokenResult => {
//       const { token, claims, signInProvider } = idTokenResult;

//       return {
//         token,
//         signInProvider,
//         role: claims.role,
//         email: claims.email,
//         uid: claims.user_id,
//       };
//     })
//     .catch(err => console.log('verifyRole error', err));

//   return auth;
// };

// /**
//  * Sign out
//  */
// export const logout = () => firebase.auth().signOut();
