import { eventChannel } from 'redux-saga';
import {
  takeLatest,
  put,
  cancel,
  cancelled,
  fork,
  take, call,
} from 'redux-saga/effects';

import * as Firebase from '../../services/firebase';
import * as types from './sessions.types';
import { LOGOUT } from '../auth/auth.types';

function* observeSessions() {
  const channel = eventChannel(emitter => {
    const observer = Firebase.sessionsObserver(emitter);

    return () => observer();
  });

  while (true) {
    try {
      const response = yield take(channel);
      yield put({
        type: types.GET_SESSIONS.SUCCESS,
        payload: response,
      });
    } catch (err) {
      yield put({
        type: types.GET_SESSIONS.FAILURE,
        payload: err,
      });
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* getSessions() {
  const observer = yield fork(observeSessions);

  yield take(LOGOUT.REQUEST);
  yield cancel(observer);
}

function* updateStatus(action) {
  try {
    yield call(Firebase.updateStatus, action?.payload);
    yield put({ type: types.UPDATE_STATUS.SUCCESS });
  } catch (err) {
    console.log('Update Status Error', err);
    yield put({
      type: types.UPDATE_STATUS.FAILURE,
    });
  }
}

export default function* payoutsSaga() {
  yield takeLatest(types.GET_SESSIONS.REQUEST, getSessions);
  yield takeLatest(types.UPDATE_STATUS.REQUEST, updateStatus);
}
