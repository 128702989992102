import firebase from 'firebase';
import { useEffect, useState } from 'react';

import CollectionManager from './collection';

import 'firebase/functions';

const Users = new CollectionManager('users');

/**
 * Get all users
 */
export const fetchUsers = () => {
  const { currentUser } = firebase.auth();
  if (currentUser) {
    return Users.getAll().then(snapshot => {
      if (snapshot.empty) {
        return Promise.reject(new Error('You are not signed in.'));
      }
      return snapshot.docs.map(doc => doc.data());
    });
  }
  return Promise.reject(new Error('You are not signed in.'));
};

export const usersObserver = emitter => {
  const { currentUser } = firebase.auth();

  if (currentUser) {
    return Users.collectionRef().onSnapshot(
      snapshot => {
        if (snapshot.emitter) return emitter({});

        return emitter(snapshot.docs.map(doc => doc.data()));
      },
      err => {
        console.log(err);
        return emitter(new Error(err));
      }
    );
  }
  return Promise.reject(new Error('You are not signed in.'));
};

/**
 * User observer
 */
export const userObserver = emitter => {
  const { currentUser } = firebase.auth();

  if (currentUser) {
    return Users.docRef(currentUser.uid).onSnapshot(
      snapshot => {
        if (!snapshot.exists) return emitter({});

        return emitter({
          id: currentUser.uid,
          ...currentUser.toJSON(),
          ...snapshot.data(),
        });
      },
      err => {
        console.log(err);
        return emitter(new Error(err));
      }
    );
  }
  return Promise.reject(new Error('You are not signed in.'));
};

export const updateUserCredits = async data => {
  try {
    console.log(data);
    await firebase.functions().httpsCallable('updateCredits')(data);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const updateUserCoach = async data => {
  try {
    console.log('Update User Coach service data', data);
    await firebase.functions().httpsCallable('updateCoachAssign')(data);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const migrateUser = async data => {
  try {
    console.log('Submitting user for migration...', data);
    const user = await firebase.functions().httpsCallable('migrateUser')(data);
    console.log('Returned user...', user);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const migrateUsersToPublic = async () => {
  try {
    await firebase.functions().httpsCallable('migrateUsersToPublic')();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const generateProfileFields = async () => {
  try {
    await firebase.functions().httpsCallable('generateProfileFields')();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const useUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers().then(setUsers);
  }, []);

  return users;
};

export const updateUser = async payload => {
  const { id, subscription } = payload;
  const { currentUser } = firebase.auth();
  if (currentUser) {
    let user = await Users.getDoc(id);
    let selectedUserData = user.data();
    let currentSubscription = {
      ...selectedUserData?.subscription,
      ...subscription,
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    };
    return Users.updateDoc(id, { subscription: currentSubscription });
  }
  return Promise.reject(new Error('You are not signed in.'));
};
