import { Alert, Pane } from 'evergreen-ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';
import { Container, Dimmer, Loader, Table, Dropdown } from 'semantic-ui-react';

import { Filter, Layout } from '../../ui';
import { getTableSortDirection } from '../../../utils';
import { updateStatus } from '../../../redux/sessions/sessions.actions';

const Sessions = ({
  clients,
  coaches,
  sessions,
  getUsers,
  getCoaches,
  getSessions,
  updateStatus,
}) => {
  useEffect(() => {
    if (clients.status === 'pending') getUsers();
  }, [clients.status, getUsers]);

  useEffect(() => {
    if (coaches.status === 'pending') getCoaches();
  }, [coaches.status, getCoaches]);

  useEffect(() => {
    if (sessions.status === 'pending') getSessions();
  }, [getSessions, sessions.status]);

  const AlertMessage = useMemo(() => {
    if (sessions.updateStatusStatus !== 'failed') return <></>;
    return (
      <Alert
        appearance="card"
        intent="danger"
        title={'Failed to update status. Please try again.'}
      />
    );
  }, [sessions]);

  const statusOptions = [
    { key: 1, text: 'IDLE', value: 'IDLE' },
    { key: 2, text: 'STARTED', value: 'STARTED' },
    { key: 3, text: 'CANCELED', value: 'CANCELED' },
    { key: 4, text: 'ENDED', value: 'ENDED' },
  ];

  const updateStatusFn = useCallback(
    selected => {
      updateStatus(selected);
    },
    [updateStatus]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Created At',
        id: 'created_at',
        accessor: row => moment(row.created_at.toDate()).format('l'),
      },
      {
        Header: 'Session Start Date',
        accessor: row =>
          row.block.start && row.block.start.toDate
            ? moment(row.block.start.toDate()).format('lll')
            : '-',
      },
      {
        Header: 'Duration',
        accessor: 'duration',
      },
      {
        Header: 'Coach',
        accessor: 'coachName',
      },
      {
        Header: 'Client',
        accessor: 'clientName',
      },
      {
        Header: 'Status',
        accessor: row => (
          <Dropdown
            key={row.id}
            placeholder="Status"
            onChange={(event, { name, value }) => {
              updateStatusFn({
                id: row.id,
                status: value,
              });
            }}
            defaultValue={row.status}
            options={statusOptions}
          />
        ),
      },
    ],
    []
  );

  const data = useMemo(() => {
    if (!sessions.list) return [];

    return sessions.list.map(session => {
      const coach = coaches.list.find(
        ({ auth }) =>
          auth.uid ===
          (session.coachUid || (session.coach && session.coach.uid))
      );

      const client = clients.users.find(
        ({ auth }) => auth.uid === session.userUid
      );

      return {
        ...session,
        coachName: coach ? coach.profile.name : '-',
        clientName: client ? client.profile.name : '-',
      };
    });
  }, [sessions.list, coaches.list, clients.users]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { sortBy: [{ id: 'created_at', desc: true }] },
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <Layout>
      <Dimmer.Dimmable as={Container} dimmed={sessions.status === 'loading'}>
        <Dimmer active={sessions.status === 'loading'} inverted>
          <Loader />
        </Dimmer>
        <Pane display="flex" alignItems="center" justifyContent="space-between">
          <Filter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Pane>
        {AlertMessage}
        <Table striped sortable celled {...getTableProps()}>
          <Table.Header>
            {headerGroups.map(headerGroup => (
              <Table.Row {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Table.HeaderCell
                    sorted={getTableSortDirection(column)}
                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <Table.Row {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <Table.Cell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Dimmer.Dimmable>
    </Layout>
  );
};

Sessions.propTypes = {
  sessions: PropTypes.objectOf(PropTypes.any).isRequired,
  clients: PropTypes.objectOf(PropTypes.any).isRequired,
  coaches: PropTypes.objectOf(PropTypes.any).isRequired,
  getUsers: PropTypes.func.isRequired,
  getCoaches: PropTypes.func.isRequired,
  getSessions: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
};

export default Sessions;
