// import { eventChannel } from 'redux-saga';
import {
  takeLatest,
  put,
  call,
  all,
  cancel,
  cancelled,
  fork,
  take,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import Swal from 'sweetalert2';

import * as types from './admin.types';
import * as Firebase from '../../services/firebase';
// import { LOGOUT } from '../auth/auth.types';

async function showConfirmationModal() {
  const response = await Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, disable it!',
  });

  return response;
}

function showSuccessModal() {
  return Swal.fire('Disabled!', 'This user has been disabled.', 'success');
}

function* disableAccount(action) {
  yield console.log('disable account saga called', action.payload);
  try {
    const confirm = yield showConfirmationModal();

    if (confirm.value === true) {
      const response = yield call(Firebase.disableAccount, action.payload);
      yield put({ type: types.DISABLE_ACCOUNT.SUCCESS });
      yield showSuccessModal();
      yield console.log('finished disable account', response);
    }
  } catch (err) {
    console.log('disable account error, ', err);
    yield put({ type: types.DISABLE_ACCOUNT.FAILURE });
  }
}

function* deleteAccount(action) {
  yield console.log('delete account saga called', action.payload);
}

// function* updateUserCredits(action) {
//   const showAlertError = () =>
//     Swal.fire('Error', 'Unable to update credits. Please try again.', 'error');

//   const showAlertSuccess = () =>
//     Swal.fire('Success', 'Session credits update successful!', 'success');

//   try {
//     yield call(Firebase.updateUserCredits, action.payload);
//     yield put({
//       type: types.UPDATE_USER_CREDITS.SUCCESS,
//     });
//     yield showAlertSuccess();
//   } catch (err) {
//     console.log(err);
//     yield put({
//       type: types.UPDATE_USER_CREDITS.FAILURE,
//       payload: err.message,
//     });
//     showAlertError();
//   }
// }

// function* observeUsers() {
//   const channel = eventChannel(emitter => {
//     const observer = Firebase.usersObserver(emitter);

//     return () => observer();
//   });

//   while (true) {
//     try {
//       const response = yield take(channel);
//       yield put({
//         type: types.GET_USERS.SUCCESS,
//         payload: response,
//       });
//     } catch (err) {
//       yield put({
//         type: types.GET_USERS.FAILURE,
//         payload: err,
//       });
//     } finally {
//       if (yield cancelled()) {
//         channel.close();
//       }
//     }
//   }
// }

// function* getUsers() {
//   const observer = yield fork(observeUsers);

//   yield take(LOGOUT.REQUEST);
//   yield cancel(observer);
// }

// function* migrateUser({ payload }) {
//   try {
//     const response = yield call(Firebase.migrateUser, payload);
//     yield put({ type: types.MIGRATE_USER.SUCCESS, payload: response });
//   } catch (err) {
//     yield put({ type: types.MIGRATE_USER.FAILURE, payload: err.message });
//   }
// }

// function* migrateUserSuccess() {
//   yield put(push('/dashboard'));
// }

export default function* migrationSaga() {
  yield takeLatest(types.DISABLE_ACCOUNT.REQUEST, disableAccount);
  yield takeLatest(types.DELETE_ACCOUNT.REQUEST, deleteAccount);
  // yield takeLatest(types.GET_USERS.REQUEST, getUsers);
  // yield takeLatest(types.GET_USERS.REQUEST, getUsers);
  // yield takeLatest(types.UPDATE_USER_CREDITS.REQUEST, updateUserCredits);
  // yield takeLatest(types.MIGRATE_USER.REQUEST, migrateUser);
  // yield takeLatest(types.MIGRATE_USER.SUCCESS, migrateUserSuccess);
}
