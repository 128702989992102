import { all, fork } from 'redux-saga/effects';

import adminSaga from '../admin/admin.saga';
import authSaga from '../auth/auth.saga';
import clientsSaga from '../clients/clients.saga';
import coachesSaga from '../coaches/coaches.saga';
import payoutsSaga from '../payouts/payouts.saga';
import invoicesSaga from '../invoices/invoices.saga';
import sessionsSaga from '../sessions/sessions.saga';
import contentSaga from '../content/content.saga';
import subscriptionsSaga from '../subscriptions/subscriptions.saga';

export default function* rootSaga() {
  yield all([]);
  yield all([
    fork(adminSaga),
    fork(authSaga),
    fork(clientsSaga),
    fork(coachesSaga),
    fork(payoutsSaga),
    fork(invoicesSaga),
    fork(sessionsSaga),
    fork(contentSaga),
    fork(subscriptionsSaga),
  ]);
}
