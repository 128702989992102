import React, { useMemo } from 'react';
import { Container } from 'semantic-ui-react';
import { Pane, Alert } from 'evergreen-ui';
import PropTypes from 'prop-types';

import MigrationForm from './CoachMigration.form';
import { Layout } from '../../ui';

const CoachMigration = ({ createCoach, coaches }) => {
  // createCoach({
  //   name: 'Amber Wilcots',
  //   email: 'amber.wilcots@yahoo.com',
  //   password: 'amber_wilcots',
  //   role: 'coach',
  // });

  const AlertMessage = useMemo(() => {
    if (!coaches.createCoachError) return <></>;
    return (
      <Alert
        appearance="card"
        intent="danger"
        title={coaches.createCoachError}
      />
    );
  }, [coaches]);

  return (
    <Layout>
      <Container>
        <Pane width={400} marginLeft="auto" marginRight="auto">
          {AlertMessage}
          <Pane height={20} />
          <MigrationForm
            loading={coaches.createCoachStatus === 'loading'}
            onSubmit={createCoach}
          />
        </Pane>
      </Container>
    </Layout>
  );
};

CoachMigration.propTypes = {
  createCoach: PropTypes.func.isRequired,
  coaches: PropTypes.any.isRequired,
};

export default CoachMigration;
