import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Pane } from 'evergreen-ui';
import {
  Form,
  Button,
  Header,
  Dimmer,
  Loader,
  Checkbox,
} from 'semantic-ui-react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import {
  VALIDATIONS,
  INTERESTS,
  AVAILABILITY_PREFERENCE,
  PASSWORD_WORDS,
  PLANS,
} from '../../../constants';
import { getRandomInt } from '../../../utils';

const CoachMigrationForm = ({ loading, onSubmit }) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    triggerValidation,
    errors,
  } = useForm();

  useEffect(() => {
    register({ name: 'name' }, { required: true });
    register({ name: 'email' }, { required: true });
  }, [register]);

  const onInputChange = async (e, { name, value }) => {
    setValue(name, value);
    await triggerValidation({ name });
  };

  const showErrors = error => {
    if (error) {
      return VALIDATIONS.MESSAGES[error.type];
    }
    return false;
  };

  const onFormSubmit = data => {
    const wordIndex = getRandomInt(0, 9);
    const randomNumber = getRandomInt(11, 99);
    const password = `${PASSWORD_WORDS[wordIndex]}${randomNumber}`;

    onSubmit({
      coach: {
        ...data,
        password,
        role: 'coach',
      },
      history,
    });
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Header>Create coach</Header>
      <Form.Input
        label="Name"
        name="name"
        onChange={onInputChange}
        error={showErrors(errors.name)}
      />
      <Form.Input
        label="Email"
        type="email"
        name="email"
        onChange={onInputChange}
        error={showErrors(errors.email)}
      />
      <Pane height={20} />
      <Button primary type="submit" fluid loading={loading} disabled={loading}>
        Save
      </Button>
    </Form>
  );
};

CoachMigrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CoachMigrationForm;
