import * as types from './payouts.types';
import { LOGOUT } from '../auth/auth.types';

const initialState = {
  status: 'pending',
  error: null,
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PAYOUTS.REQUEST:
    case types.APPROVE_PAYOUT.REQUEST:
    case types.GENERATE_PAYOUTS.REQUEST:
    case types.GENERATE_INVOICES.REQUEST:
      return {
        ...state,
        error: null,
        status: 'loading',
      };
    case types.GET_PAYOUTS.SUCCESS:
      return {
        ...state,
        list: action.payload,
        status: 'success',
      };
    case types.APPROVE_PAYOUT.SUCCESS:
    case types.GENERATE_PAYOUTS.SUCCESS:
    case types.GENERATE_INVOICES.SUCCESS:
      return {
        ...state,
        status: 'success',
      };
    case types.APPROVE_PAYOUT.FAILURE:
    case types.GENERATE_PAYOUTS.FAILURE:
    case types.GENERATE_INVOICES.FAILURE:
      return {
        ...state,
        error: action.payload,
        status: 'failed',
      };
    case LOGOUT.SUCCESS:
      return initialState;

    default:
      return state;
  }
};
