import React, { useEffect } from 'react';
import { Container, Button, Dimmer, Loader } from 'semantic-ui-react';
import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';

import { Layout } from '../../ui';
import {
  useCoaches,
  migrateUsersToPublic,
  addRoleToCoach,
  generateProfileFields,
} from '../../../services/firebase';

const Scripts = ({ clients, getUsers }) => {
  const coaches = useCoaches();

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  if (!clients.users || !coaches) {
    return (
      <Dimmer active inverted>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <Layout>
      <Container>
        <Pane display="flex" justifyContent="center">
          <Button
            primary
            floated="left"
            onClick={() => migrateUsersToPublic()}
            disabled={clients.status === 'loading'}>
            Generate Users Public Collection
          </Button>
          <Button
            primary
            floated="left"
            onClick={() => addRoleToCoach()}
            disabled={clients.status === 'loading'}>
            Add Roles to Coaches
          </Button>
          <Button
            primary
            floated="left"
            onClick={() => generateProfileFields()}
            disabled={clients.status === 'loading'}>
            Generate Profile Fields
          </Button>
        </Pane>
      </Container>
    </Layout>
  );
};

Scripts.propTypes = {
  clients: PropTypes.objectOf(PropTypes.any).isRequired,
  getUsers: PropTypes.func.isRequired,
};

export default Scripts;
