import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import SuperRoute from './SuperRoute';
import Login from '../components/views/Login';
import Dashboard from '../components/views/Dashboard';
import Users from '../components/views/Users';
import Coaches from '../components/views/Coaches';
import Payouts from '../components/views/Payouts';
import Sessions from '../components/views/Sessions';
import Content from '../components/views/WebAppContent';
import Scripts from '../components/views/Scripts';
import Migration from '../components/views/Migration';
import CoachMigration from '../components/views/CoachMigration';
import NotFound from '../components/views/NotFound';

export default (
  <Switch>
    <PrivateRoute
      exact
      path="/"
      componentProps={{ to: '/users' }}
      component={Redirect}
    />
    <Route exact path="/login" component={Login} />
    <PrivateRoute exact path="/dashboard" component={Dashboard} />
    <PrivateRoute exact path="/users" component={Users} />
    <PrivateRoute exact path="/coaches" component={Coaches} />
    <SuperRoute exact path="/payouts" component={Payouts} />
    <PrivateRoute exact path="/sessions" component={Sessions} />
    <PrivateRoute exact path="/content" component={Content} />
    <PrivateRoute exact path="/scripts" component={Scripts} />
    <PrivateRoute exact path="/migration" component={Migration} />
    <PrivateRoute exact path="/coach-migration" component={CoachMigration} />
    <Route component={NotFound} />
  </Switch>
);
