import { createAction } from 'redux-actions';

import * as types from './payouts.types';

export const getPayouts = createAction(types.GET_PAYOUTS.REQUEST);

export const approvePayout = createAction(types.APPROVE_PAYOUT.REQUEST);

export const generateInvoices = createAction(types.GENERATE_INVOICES.REQUEST);

export const generatePayouts = createAction(types.GENERATE_PAYOUTS.REQUEST);
