import { connect } from 'react-redux';

import Users from './Users.view';
import {
  getUsers,
  updateUserCredits,
} from '../../../redux/clients/clients.actions';
import { getCoaches } from '../../../redux/coaches/coaches.actions';
import {
  disableAccount,
  deleteAccount,
} from '../../../redux/admin/admin.actions';
import { getSubscriptions } from '../../../redux/subscriptions/subscriptions.actions';

const mapState = ({ clients, coaches, subscriptions }) => ({
  clients,
  coaches,
  subscriptions,
});

export default connect(mapState, {
  getUsers,
  getCoaches,
  getSubscriptions,
  updateUserCredits,
  disableAccount,
  deleteAccount,
})(Users);
