import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  componentProps,
  auth,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        auth.status === 'success' && auth.super ? (
          <Component {...{ ...props, ...componentProps }} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default connect(({ auth }) => ({ auth }))(PrivateRoute);
