import { createAction } from 'redux-actions';

import * as types from './clients.types';

export const getUsers = createAction(types.GET_USERS.REQUEST);

export const updateUserCredits = createAction(
  types.UPDATE_USER_CREDITS.REQUEST
);

export const migrateUser = createAction(types.MIGRATE_USER.REQUEST);
export const updateUserCoach = createAction(types.UPDATE_USER_COACH.REQUEST);

export const updateChat = createAction(types.UPDATE_CHAT.REQUEST);
export const updateVideo = createAction(types.UPDATE_VIDEO.REQUEST);
