import firebase from 'firebase';

import CollectionManager from './collection';

import 'firebase/functions';

const Payouts = new CollectionManager('payouts');

export const payoutsObserver = emitter => {
  const { currentUser } = firebase.auth();

  if (currentUser) {
    return Payouts.collectionRef().onSnapshot(
      snapshot => {
        if (snapshot.emitter) return emitter({});

        return emitter(
          snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        );
      },
      err => {
        console.log(err);
        return emitter(new Error(err));
      }
    );
  }
  return Promise.reject(new Error('You are not signed in.'));
};

export const approvePayout = async data => {
  try {
    await firebase.functions().httpsCallable('approvePayout')(data);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const generateInvoices = async () => {
  try {
    await firebase.functions().httpsCallable('generateInvoices')();
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const payoutsScheduler = async () => {
  try {
    await firebase.functions().httpsCallable('payoutsScheduler')();
  } catch (err) {
    console.error(err);
    throw err;
  }
};
