import { createAsyncActionTypes } from '../utils';

export const GET_USERS = createAsyncActionTypes('GET_USERS');
export const UPDATE_USER_CREDITS = createAsyncActionTypes(
  'UPDATE_USER_CREDITS'
);
export const MIGRATE_USER = createAsyncActionTypes('MIGRATE_USER');
export const UPDATE_USER_COACH = createAsyncActionTypes('UPDATE_USER_COACH');

export const UPDATE_VIDEO = createAsyncActionTypes('UPDATE_VIDEO');
export const UPDATE_CHAT = createAsyncActionTypes('UPDATE_CHAT');
