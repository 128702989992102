import { connect } from 'react-redux';

import Sessions from './Sessions.view';
import { getSessions, updateStatus } from '../../../redux/sessions/sessions.actions';
import { getUsers } from '../../../redux/clients/clients.actions';
import { getCoaches } from '../../../redux/coaches/coaches.actions';

const mapState = ({ clients, coaches, sessions }) => ({
  clients,
  coaches,
  sessions,
});

export default connect(mapState, {
  getUsers,
  getCoaches,
  getSessions,
  updateStatus,
})(Sessions);
