import firebase from 'firebase';
import 'firebase/functions';

/**
 * State change observer
 */
export const onAuthStateChanged = (successFn, failureFn) =>
  firebase
    .auth()
    .onAuthStateChanged(user => (user ? successFn(user) : failureFn()));

/**
 * Sign in to firebase with email and password credential
 */
export const loginWithEmailAndPassword = ({ email, password }) =>
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(res => JSON.stringify(res))
    .then(res => JSON.parse(res))
    .then(res => {
      return {
        token: res.user.stsTokenManager.refreshToken,
        uid: res.user.uid,
      };
    })
    .catch(err => {
      console.log('ERROR - sign in with email and password: ', err);
      switch (err.code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
        case 'auth/wrong-password':
        default:
          throw err;
      }
    });

/**
 * Verify Role
 */
export const verifyRole = async () => {
  // const { uid } = firebase.auth().currentUser;
  // // const adminCheck = ['MTyeydMLytb9ngt8IIvXbNKaKOE3'];
  // const adminCheck = ['b8Vhd1UJsxNoVU6dPgdY9BoaEEA3'];
  // // console.log('Verify Role UID', uid);

  // if (adminCheck.includes(uid)) {
  //   console.log('Creating a new super user', uid);
  //   await firebase.functions().httpsCallable('setAdminPrivileges')({
  //     userUid: uid,
  //     adminStatus: true,
  //   });
  //   await firebase.functions().httpsCallable('setSuperPrivileges')({
  //     userUid: uid,
  //     superStatus: true,
  //   });
  //   await firebase.functions().httpsCallable('setMasterPrivileges')({
  //     userUid: uid,
  //     masterStatus: true,
  //   });

  //   return {
  //     admin: true,
  //     uid,
  //   };
  // }

  const auth = await firebase
    .auth()
    .currentUser.getIdTokenResult(true)
    .then(idTokenResult => {
      const { token, claims, signInProvider } = idTokenResult;

      // console.log('claims', claims);

      return {
        token,
        signInProvider,
        admin: claims.admin,
        super: claims.super,
        master: claims.master,
        role: claims.role,
        email: claims.email,
        uid: claims.user_id,
      };
    })
    .catch(err => console.log('verifyRole error', err));

  return auth;
};

/**
 * Verify Admin
 */
export const verifyAdmin = async () => {
  const auth = await firebase
    .auth()
    .currentUser.getIdTokenResult(true)
    .then(idTokenResult => {
      const { token, claims, signInProvider } = idTokenResult;

      return {
        token,
        signInProvider,
        admin: claims.admin,
        email: claims.email,
        uid: claims.user_id,
      };
    })
    .catch(err => console.log('verifyAdmin error', err));

  return auth;
};

/**
 * Set Admin Privileges
 */
export const setAdminPrivileges = async data => {
  try {
    const { uid } = firebase.auth().currentUser;

    if (uid) {
      const { userUid, adminStatus, superStatus, masterStatus } = data;

      if (typeof userUid === 'string' && typeof adminStatus === 'boolean') {
        return await firebase.functions().httpsCallable('setMasterPrivileges')({
          userUid,
          // adminStatus,
          // superStatus,
          masterStatus,
        });
      }

      throw new Error('Type mismatch, please check the data and try again.');
    }

    throw new Error('You are not signed in.');
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateUserCredits = async data => {
  try {
    console.log(data);
    await firebase.functions().httpsCallable('updateCredits')(data);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

/**
 * Sign out
 */
export const logout = () => firebase.auth().signOut();
