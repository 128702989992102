import { throttle, put, call } from 'redux-saga/effects';

import * as Firebase from '../../../services/firebase';
import { LOGIN_WITH_EMAIL, VERIFY_ROLE } from '../auth.types';

function* loginWithEmail({ payload }) {
  try {
    const response = yield call(Firebase.loginWithEmailAndPassword, payload);
    yield put({
      type: LOGIN_WITH_EMAIL.SUCCESS,
      payload: response,
    });
    yield put({ type: VERIFY_ROLE.REQUEST });
  } catch (err) {
    yield put({ type: LOGIN_WITH_EMAIL.FAILURE, payload: err.message });
  }
}

export default function* loginSaga() {
  yield throttle(1000, LOGIN_WITH_EMAIL.REQUEST, loginWithEmail);
}
