import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from '../../../theme';

export const Label = styled.label`
  &&&& {
    color: ${colors.nightRider};
    font-size: 14px;
  }
`;

export const Ahref = styled(Link)`
  color: ${colors.bittersweet};
  font-size: 14px;
  cursor: pointer;
`;
